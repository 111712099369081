<template>
    <div class="page convert">
        <div class="top">
            <div class="title">请输入您的权益码领取权益</div>
        </div>
        <div class="form">
            <div class="group">
                <div class="label">权益码</div>
                <div class="input">
                    <input type="text" placeholder="请输入权益码" v-model="convertCode"/>
                </div>
            </div>
            <div class="group">
                <div class="label">验证码</div>
                <div class="input">
                    <input type="text" placeholder="请输入图形验证码" v-model="verify"/>
                    <img @click="changeVerify" :src="captchaUrl" class="verifyImg">
                </div>
            </div>
            <div class="button" @click="onSubmit">领取</div>
            <div class="goHome" @click="goHome">我已领取，点击查看</div>
        </div>
        <div class="bottom">
            <BottomCom/>
        </div>
    </div>
</template>

<script>
    import {Toast} from 'vant';
    import _ from 'lodash';
    import dayJs from 'dayjs';
    import config from "../config"
    import BottomCom from "../components/BottomCom";
    import {equityExchange} from "../api/data";

    export default {
        name: "Convert",
        components: {BottomCom},
        data: function () {
            return {
                captchaUrl: '',
                convertCode: '',
                verify: '',
            }
        },
        beforeMount() {
            this.changeVerify();
        },
        methods: {
            changeVerify() {
                let baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;
                let timestamp = dayJs().unix();
                let token = localStorage.getItem("token");
                let captchaUrl = `${baseUrl}login/yzm?token=${token}&timestamp=${timestamp}`;
                this.captchaUrl = captchaUrl;
            },
            async onSubmit() {
                if (this.convertCode == null || this.convertCode == '') {
                    Toast.fail("请先输入权益码");
                    return false;
                }
                if (this.verify == null || this.verify == '') {
                    Toast.fail("请输入图形验证码");
                    return false;
                }
                let params = {
                    yzm: this.verify,
                    barcode: this.convertCode,
                };
                let res = await equityExchange(params);
                if (_.toInteger(res.code) === 1) {
                    this.goHome();
                } else {
                    this.changeVerify();
                    Toast.fail(res.msg);
                }
            },
            goHome() {
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>

    .convert {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
    }

    .convert .top {
        width: 630px;
        margin-top: 51px;
        margin-left: 16px;
    }

    .convert .top .title {
        font-size: 36px;
        font-weight: 800;
        line-height: 50px;
        color: #A05923;
    }

    .convert .form {
        width: 630px;
        margin-top: 87px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 30px;
    }

    .convert .form .group {
        width: 570px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 48px;
    }

    .convert .form .group .label {
        width: 570px;
        font-size: 30px;
        font-weight: 400;
        line-height: 42px;
        color: #141E2A;
    }

    .convert .form .group .input {
        width: 570px;
        margin-top: 24px;
        margin-bottom: 17px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .convert .form .group .input input {
        flex: 1;
        border: 0;
    }


    .convert .form .group .input .verifyImg {
        width: 141px;
        height: 55px;
    }

    .convert .form .group .input input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #C7C7C7;
        font-size: 30px;
    }

    .convert .form .group .input input::-moz-placeholder {
        /* WebKit browsers */
        color: #C7C7C7;
        font-size: 30px;
    }

    .convert .form .group .input input::-moz-placeholder {
        /* WebKit browsers */
        color: #C7C7C7;
        font-size: 30px;
    }

    .convert .form .group .input input:-ms-input-placeholder {
        color: #C7C7C7;
        font-size: 30px;
    }

    .convert .form .button {
        height: 80px;
        background: linear-gradient(107deg, #3C3F45 0%, #1F2226 100%);
        font-size: 26px;
        font-weight: bold;
        border-radius: 16px;
        color: #EFDAC0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .convert .form .goHome {
        height: 80px;
        margin-top: 25px;
        border: 2px solid #212428;
        font-size: 26px;
        font-weight: bold;
        border-radius: 16px;
        color: #202327;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .convert .bottom {
        width: 690px;
        height: 490px;
        background: url("../assets/body_bg.png") no-repeat right bottom;
        background-size: auto 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
